import React from "react";
import { Link as LinkR } from "react-scroll";

function Nft() {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <section className="character-area pt-100 pb-100 character-area-shadow">
      <div className="container-myc">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="section-title title-style-two text-center mb-5">
              <a
                data-wow-delay="0.3s"
                data-wow-duration="1s"
                className="btnd wow fadeInLeft g-hover"
                style={{ textDecoration: "none" }}
                onClick={() => scrollToSection("MINT")}
              >
                <LinkR to="Home" smooth={true} duration={50} offset={-50}>
                  Mint Now: XLayer collection!
                </LinkR>
              </a>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title title-style-two text-center mb-70">
              <h3 className="sub-title mb-0">OPEN AND COLLECT NFT</h3>
              <h2 className="title">X L A Y E R C H A I N</h2>
            </div>
          </div>
        </div>

        <div className="row justify-content-center ">
          <div className="col-12 col-lg-3 col-sm-12 custom-col  mt-3   position-relative car-1-ms p-0 ">
            <div className="d-flex justify-content-center">
              <img
                className="alltuchtopdown opening2 opening imageSize"
                src="assets/img/images/1.png"
                alt="line"
                
              />
            </div>
            <div className="w-100 d-flex justify-content-center align-items-center">
              <div className="gallery-content box1">
                <h3>Xlayer Chain</h3>
                <img
                  className=" chain-line pb-10"
                  src="assets/img/images/Line.png"
                  alt="line"
                
                />
                <span className="">rarity</span>
                <h2>Epic</h2>
                <span>Quantity</span>
                <h2>780</h2>
                <img
                  className=" okx  mb-10"
                  src="assets/img/images/x-Layer.png"
                  alt="okx-img"
                />
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-3 col-sm-12 custom-col  mt-3 position-relative car-1-ms p-0 ">
            <div className="d-flex justify-content-center">
              <img
                className="   alltuchtopdown opening imageSize"
                src="assets/img/images/2.png"
                alt="line"
              />
            </div>
            <div className="w-100 d-flex justify-content-center align-items-center">
              <div className="gallery-content box2 ">
                <h3>Xlayer Chain</h3>
                <img
                  className="chain-line pb-10 "
                  src="assets/img/images/Line.png"
                  alt="line"
                />
                <span className="">rarity</span>
                <h2>Epic</h2>
                <span>Quantity</span>
                <h2>780</h2>
                <img
                  className="okx  mb-10"
                  src="assets/img/images/x-Layer.png"
                  alt="okx-img"
                />
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-3 col-sm-12 custom-col  mt-3 position-relative car-1-ms p-0 ">
            <div className="d-flex justify-content-center">
              <img
                className="  alltuchtopdown opening imageSize"
                src="assets/img/images/3.png"
                alt="line"
              />
            </div>
            <div className="w-100 d-flex justify-content-center align-items-center ">
              <div className="gallery-content box3">
                <h3>Xlayer Chain</h3>
                <img
                  className="chain-line pb-10 "
                  src="assets/img/images/Line.png"
                  alt="line"
                />
                <span className="">rarity</span>
                <h2>Epic</h2>
                <span>Quantity</span>
                <h2>780</h2>
                <img
                  className="okx  mb-10"
                  src="assets/img/images/x-Layer.png"
                  alt="okx-img"
                />
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-3 col-sm-12 custom-col  mt-3 position-relative car-1-ms p-0 ">
            <div className="d-flex justify-content-center">
              <img
                className="  alltuchtopdown opening imageSize"
                src="assets/img/images/4.png"
                alt="line"
              />
            </div>
            <div className="w-100 d-flex justify-content-center align-items-center">
              <div className="gallery-content box1">
              <h3>Xlayer Chain</h3>
              <img
                  className="chain-line pb-10 "
                  src="assets/img/images/Line.png"
                  alt="line"
                />
                <span className="">rarity</span>
                <h2>Epic</h2>
                <span>Quantity</span>
                <h2>780</h2>
                <img
                  className="okx  mb-10"
                  src="assets/img/images/x-Layer.png"
                  alt="okx-img"
                />
              </div>
            </div>
          </div>

          {/* <div className="col-md-3 char-item">
            <div className="gallery-content box2">
              <h3>OKX CHAIN</h3>
              <img
                className="chain-line pb-10"
                src="assets/img/images/Line.png"
                alt="line"
              />
              <span>rarity</span>
              <h2>Epic</h2>
              <span>Quantity</span>
              <h2>780</h2>
              <img
                className="okx  mb-10"
                src="assets/img/images/okx-img.png"
                alt="okx-img"
              />
            </div>
            <img
              className="opening alltuchtopdown"
              src="assets/img/images/2.png"
              alt="okx-img"
            />
          </div>
          <div className="col-md-3 char-item">
            <div className="gallery-content box3">
              <h3>OKX CHAIN</h3>
              <img
                className="chain-line pb-10"
                src="assets/img/images/Line.png"
                alt="line"
              />
              <span>rarity</span>
              <h2>Epic</h2>
              <span>Quantity</span>
              <h2>780</h2>
              <img
                className="okx  mb-10"
                src="assets/img/images/okx-img.png"
                alt="okx-img"
              />
            </div>
            <img
              className="opening alltuchtopdown"
              src="assets/img/images/3.png"
              alt="img"
            />
          </div>
          <div className="col-md-3 char-item mb-0">
            <div className="gallery-content box4">
              <h3>OKX CHAIN</h3>
              <img
                className="chain-line pb-10"
                src="assets/img/images/Line.png"
                alt="line"
              />
              <span>rarity</span>
              <h2>Epic</h2>
              <span>Quantity</span>
              <h2>780</h2>
              <img
                className="okx  mb-10"
                src="assets/img/images/okx-img.png"
                alt="okx-img"
              />
            </div>
            <img
              className="opening alltuchtopdown"
              src="assets/img/images/4.png"
              alt="img"
            />
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default Nft;
