import React, { useEffect, useState } from "react";
import bgimage from "../mintims/telegram-cloud-document-4-5900135907549253113 1.jpg";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { useWeb3Modal } from "@web3modal/ethers5/react";
import switchChain from "./navbars/switchChain";
import LootBoxAddress from "../components/Contracts/LootBoxAddress.json";
import LootBoxAbi from "../components/Contracts/LootBoxAbi.json";
import { ethers, providers } from "ethers";
import CharacterAddress from "../components/Contracts/Character_Collection_Address.json";
import SwordAddress from "../components/Contracts/Sword_Collection_Address.json";
import CollectionAbi from "../components/Contracts/CollectionAbi.json";
import { toast } from "react-toastify";
import {
  useMutateAddTxHash,
  useMutateDeleteUser,
  useMutateOpenLootBox,
} from "../hooks/mutation";

import "./styles.css";
import axios from "axios";
import Modal from "./WaitingModal";
import { endPoint } from "../hooks/queryConstant";
const Mint = () => {
  const [count, setCount] = useState(1);
  const [supply, setSupply] = useState(0);
  const [price, setPrice] = useState(0);
  const [totalMinted, setTotalMinted] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const { isConnected, address, chainId } = useWeb3ModalAccount();
  const [loading, setLoading] = useState(false);
  const { open } = useWeb3Modal();
  const { walletProvider } = useWeb3ModalProvider();

  const { mutate: handleAddTxHash, isPending: isLoadingUserDetails } =
    useMutateAddTxHash();
  const { mutate: handleDeleteUser } = useMutateDeleteUser();

  const mintNow = async () => {
    try {
      const data = {
        address: address,
        isWhitelistmint: true,
        size: count,
        nftName: "okx",
      };
      axios
        .post(`${endPoint}/openLootbox`, data, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
        })
        .then(async (res) => {
          lootBox(res);
        })
        .catch((error) => {
          // Handle the error here
          console.error("An error occurred:", error);
          // You can optionally display a message to the user or perform other actions
        });
    } catch (error) {
      toast.error(error.reason);
      console.log(error);
    }
  };

  const lootBox = async (res) => {
    try {
      if (chainId !== 196) {
        // open()
        switchChain();
      }
      setLoading(true);
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();
      const lootBox = new ethers.Contract(
        LootBoxAddress.address,
        LootBoxAbi.abi,
        signer
      );
      console.log(res);
      const { r, s, v } = ethers.utils.splitSignature(
        res?.data?.data?.signature?.sig
      );
      const deadline = res?.data?.data?.signature.time;
      const Proof = {
        v,
        r,
        s,
        deadline,
      };
      const Collection = new ethers.Contract(
        CharacterAddress.address,
        CollectionAbi,
        signer
      );
      const nftPrice = await Collection.getWhitelistPrice();
      console.log(res?.data?.data?.ids);
      const txhash = await lootBox.lootBox(
        res?.data?.data?.ids?.swordsIds,
        res?.data?.data?.ids?.charactersIds,
        res?.data?.data?.signature?.nonce.toString(),
        SwordAddress.address,
        CharacterAddress.address,
        address,
        true,
        Proof,
        { value: nftPrice.toString() * count }
      );
      // if (txhash?.hash) {
      //   handleAddTxHash({ address: address, transactionHash: txhash.hash });
      // }
      const reciept = await txhash.wait();
      if (reciept.status == 1) {
        setLoading(false);
      }
    } catch (error) {
      handleDeleteUser({ address: address });
      setLoading(false);
      console.log(error);
      toast.error(error?.data?.message);
      toast.error(error?.reason);
      if (error.code == "ACTION_REJECTED") {
        toast.error("Transaction was rejected by the user.");
      }
    }
  };
  const totalSupply = async () => {
    try {
      const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = ethersProvider.getSigner();
      const Collection = new ethers.Contract(
        CharacterAddress.address,
        CollectionAbi,
        signer
      );
      const nftPrice = await Collection.getWhitelistPrice();
      setPrice(nftPrice.toString());
      const totalMinted = await Collection.totalMinted();
      console.log(totalMinted.toString());
      const perc = (totalMinted / 10000) * 100;
      setPercentage(perc);
      setTotalMinted(totalMinted.toString());
    } catch (error) {
      toast.error(error);
      console.log(error);
    }
  };
  useEffect(() => {
    totalSupply();
  }, [isConnected]);

  // useEffect(() => {
  //   if (chainId !== 95) {
  //     switchChain();
  //   }
  // }, [chainId]);

  return (
    <>
      <div
        id="Home"
        className="mint-bg bordermy text-white d-flex character-area-shadow flex-column align-items-center custom-container px-3 px-lg-5 px-md-5"
        style={{
          backgroundImage: `url(${bgimage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          paddingBottom: "40px",
          boxShadow:
            "rgb(0 0 0 / 100%) 45px 0px 18px -27px inset,rgb(0 0 0 / 80%) -12px 0px 8px -4px inset",
          // height: "70vh",
          // borderTop: "1px solid green",
        }}
      >
        <div className="main-line"></div>

        <h1 className="mint-here khand fw-bold pt-5">MINT HERE</h1>
        <p className="px-3 jost text-center mint-p">
          In this lootbox, you will mint 1 random NFT of character or sword.
        </p>
        <div className="position-relative lb-w  mb-3 mb-md-5">
          <div className="position-absolute background-div"></div>
          <div className="lootbox  flex-column">
            <div className="d-flex gap-3 justify-content-center align-items-center">
              {/* <img src={x35} alt="Not found" /> */}
              <h2 className="khand lb-head fw-bold">Xlayer LOOTBOX</h2>
            </div>{" "}
            <hr />
            <div>
              <p className="total jost">Total available (Sold/Total)</p>
              <div className="d-flex gap-4 justify-content-between">
                <h4 className="jost text-my-w">
                  <span className="mint-here jost ">{totalMinted}</span>/ 10000
                </h4>
                <input
                  type="range"
                  className="custom-range"
                  min="0"
                  max="100"
                  value={percentage}
                />
              </div>
            </div>
            <hr />
            <div>
              <div className="d-flex justify-content-between jost">
                <h4 className="text-my-w fw-5">
                  <span className="mint-here fw-6">Price</span>/Mint
                </h4>
                <h4 className="text-my-w fw-5">
                  <span className="mint-here fw-6">$0.01</span>/Mint
                </h4>
              </div>
              <p className="total">Total available (Sold/Total)</p>
            </div>
            <hr />
            <div className="d-flex justify-content-between align-items-center jost">
              <p className="text-my-w m-0 align-items-center">Chain</p>
              <div className="d-flex gap-3">
                {/* <img src={okx} alt="Not found" className="h-25 mt-1" /> */}
                <p className="m-0 okbchain-button">OKB Chain</p>
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-center gap-4">
              <div className="count d-flex justify-content-center align-items-center gap-1 px-3 jost">
                <button
                  onClick={() => {
                    if (count > 0) {
                      setCount(count - 1);
                    }
                  }}
                  className="count-btn fw-bold"
                  style={{
                    border: "none",
                    fontSize: "30px",
                    marginBottom: "2px",
                  }}
                >
                  -
                </button>
                <span className="mint-here fw-bold">{count}</span>
                <button
                  onClick={() => setCount(count + 1)}
                  className="count-btn fw-bold"
                  style={{ border: "none" }}
                >
                  +
                </button>
              </div>
              {isConnected ? (
                <button
                  disabled={loading}
                  className="mint-button px-4 fw-bold jost"
                  onClick={mintNow}
                >
                  {loading ? "Minting..." : "Mint Now"}
                </button>
              ) : (
                <button
                  className="mint-button px-4 fw-bold jost"
                  onClick={() => open()}
                >
                  Connect Wallet
                </button>
              )}
            </div>
            <hr />
            <div className="d-flex justify-content-between align-items-center flex-column flex-sm-row">
              <button className="mint-here salebtn fw-bold bg-black  jost ">
                PUBLIC SALE
              </button>
              <div className="jost mt-2 mt-sm-0">
                <span className="mint-here">PRICE:</span>0.040 OKB <br />
                <span className="mint-here">LIMIT:</span>75 NFTS
              </div>
            </div>
          </div>
        </div>
        {loading && <Modal />}

        <div className="main-line"></div>
      </div>
    </>
  );
};

export default Mint;
